.about {
  &__block {
    background: #1488cc; /* fallback for old browsers */
    background: -webkit-linear-gradient(
      to right,
      #2b32b2,
      #1488cc
    ); /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(
      to right,
      #2b32b2,
      #1488cc
    ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
    color: #fff;
    font-size: 18px;

    border-radius: 10px;
    max-width: 700px;
    padding: 30px;
    margin: 0 auto;
    margin-top: 30px;
    margin-bottom: 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
    img {
      margin-bottom: 50px;
    }
  }
  &__imgs {
    display: flex;
    justify-content: space-evenly;
  margin-bottom: 100px;
  div {
      width: 30%;
      img {
        width: 100%;
        margin-bottom: 20px;
      }
      color: #fff;
      font-weight: 500;
      font-size: 18px;
      text-align: center;
    }
    .abc {
      transform: rotate(270deg);
    }
  }
}
