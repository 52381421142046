@import "../../variables";

.courses {
  &__items {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 60px;
  }
  &__item {
    width: 450px;
    height: 260px;
    background-image: url("../../assets/images/b1.png");
    background-position: center;
    background-size: cover;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
    border-radius: 10px;
  }
  &__content {
    width: 50%;
    button {
      background-color: #fff;
      border-radius: 5px;
      padding: 8px 10px;
      color: #006a6e;
      font-weight: 900;
    }
  }
  &__name {
    font-size: 26px;
    font-weight: 900;
    margin-bottom: 14px;
  }
  &__descr {
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 12px;
  }
  &__duration {
    font-weight: 800;
    margin-bottom: 12px;
  }
  &__img {
    width: 45%;
    img {
      width: 100%;
    }
  }
  &__search {
    border: 1px solid #006a6e;
    border-radius: 5px;
    height: 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 35px;
    margin-bottom: 90px;
    input {
      color: #006a6e;
      margin-left: 35px;
      height: 100%;
      width: 300px;
      font-size: 18px;
      &::placeholder {
        color: #99b5b6;
      }
    }
    button {
      background: #006a6e;
      color: #fff;
      width: 120px;
      height: 100%;
      font-size: 20px;
      font-weight: bold;
    }
  }
  &__searchLeft {
    display: flex;
    align-items: center;
  }
}
@media screen and (max-width: 430px) {
  .courses__item {
    width: 100%;
  }
  .courses__searchLeft input {
    width: 100%;
    font-size: 14px;
  }

  .courses__img {
  }
}
