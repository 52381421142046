.main {
  margin-top: 70px;
  font-family: Montserrat;
  color: #006a6e;
}
.hero {
  background-image: url("../../assets/images/mainBannerBg.png");
  width: 100%;
  height: 300px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  justify-content: space-around;
}

.uploadBtn {
  position: fixed;
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 10px 15px;
  bottom: 10%;
  right: 15%;
  background-color: #006a6e;
  color: white;
  border: none;
  border-radius: 20px;
  font-size: 1em;
  cursor: pointer;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
}

.heroLeft {
  display: flex;
  flex-direction: column;
  width: 430px;
  height: 100%;
  text-align: center;
  justify-content: center;
}

.heroRight {
  width: 262px;
  margin-top: 13px;
}

.heroRight img {
  width: 100%;
}

.heroTitle {
  font-size: calc(24px + 20 * (100vw / 1096));
  color: #fff;
  font-weight: 900;
  margin-bottom: 38px;
}

.heroDescr {
  color: #fff;
  font-size: calc(18px + 6 * (100vw / 1096));
  font-weight: 800;
}

.heroLabel {
  font-weight: 900;
  font-size: 25px;
  width: 100%;
  text-align: center;
  margin: 38px auto 90px;
}

/* ABOUT ------------------*/
.about {
}

.aboutUs {
  color: #e00f61;
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 18px;
}
.aboutTitle {
  font-size: 50px;
  font-weight: 900;
}

.aboutContent {
  display: flex;
}

.aboutImage {
  width: 400px;
  height: 400px;
  margin-right: 110px;
}

.aboutImage img {
  width: 100%;
}

.aboutText {
  width: 530px;

  font-size: 20px;
}

.aboutText p {
  font-weight: 500;
}

.aboutList {
  font-weight: 700;
  list-style-type: none;
}

.aboutList li {
  margin-bottom: 14px;
}

.aboutList li a {
  display: inline-block;
  border: 2px solid #006a6e;
  padding: 5px 15px;
  color: #006a6e;
  border-radius: 25px;
  font-size: 20px;
}

.aboutFooter {
  width: 800px;
  margin: 80px auto 90px;

  font-size: 25px;
  font-weight: 900;
  text-align: center;
}

/* Certificates----------------- */

.certificates {
  background: #cdf5f7;
  padding: 40px 0 76px;
}

.certificatesTitle {
  font-size: 50px;
  font-weight: 900;
  text-align: center;
  margin-bottom: 38px;
}

.certificatesDescr {
  width: 900px;
  font-size: 25px;
  font-weight: 500;
  margin: 0 auto 80px;
  text-align: center;
}

.certificateImages {
  display: flex;
  justify-content: space-between;
  background-color: #fff;
  padding: 20px 30px;
  border-radius: 10px;
  font-size: 20px;
  font-weight: 700;
  text-align: center;
  border-top: 12px solid #006a6e;
}

.certificateImgLeft {
  width: 580px;
  height: 400px;
}

.certificateImgRight {
  width: 428px;
}

.certificateImage2 {
  width: 270px;
}

.certificateImage1 {
  height: 100%;
}

.certificateImageDescr {
  margin-top: 20px;
}

/* Testimonials ----------- */

.testimonials {
  padding-top: 132px;
}

.testimonialsText {
  color: #e00f61;
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 18px;
}

.testimonialsTitle {
  color: #006a6e;
  font-size: 80px;
  font-weight: 900;
  text-align: center;
  margin: 14px 0 120px;
}

.testimonialCards {
  display: flex;
  justify-content: space-around;
}

.testimonialCard {
  width: 400px;
  background: #fff;
  border-radius: 10px;
  box-shadow: 0px 5px 4px 0px rgba(0, 0, 0, 0.1);
  padding: 10px 8px;
}

.testimonialCardContainer {
  background: #cdf5f7;
  border-radius: 10px;
  height: 100%;
  padding: 10px 18px 20px;
}
.testimonialCardHeader {
  display: flex;
  align-items: center;
  margin-bottom: 26px;
}

.testimonialCardImg {
  width: 70px;
  height: 70px;
  margin: 0 55px 0 20px;
  overflow: hidden;
  border-radius: 50%;
}
.testimonialCardImg img {
  width: 100%;
}
.testimonialCardName {
  font-size: 20px;
  color: #000;
  font-weight: 700;
}
.testimonialCardDescr {
  color: #000;
  font-size: 14px;
}

/* Footer ----------- */

.container {
  background-color: #2d6a4f;
  border-radius: 8px;
  padding: 20px;
  width: 80%;
  max-width: 600px;
  text-align: center;
}

.content h1 {
  color: white;
  margin-bottom: 20px;
  font-size: 1.5em;
}

.content p {
  color: white;
  margin-bottom: 20px;
  font-size: 1em;
}

.buttons {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-bottom: 20px;
}

.btn {
  background-color: white;
  color: #2d6a4f;
  border: none;
  border-radius: 20px;
  padding: 10px;
  font-size: 1em;
  cursor: pointer;
}

.btn:hover {
  background-color: #e9ecef;
}

.footer p {
  color: white;
  margin-bottom: 20px;
  font-size: 1em;
}

.download-btn {
  background-color: #2d6a4f;
  color: white;
  border: 2px solid white;
  border-radius: 20px;
  padding: 10px;
  font-size: 1em;
  cursor: pointer;
}

.download-btn:hover {
  background-color: #1b4332;
}

/* Стили для модального окна */
.modalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modalContent {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  width: 400px;
  max-width: 100%;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  text-align: center;
}

.closeButton {
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: transparent;
  border: none;
  font-size: 20px;
  color: #333;
  cursor: pointer;
}

.closeButton:hover {
  color: #e74c3c;
}

.modalTitle {
  font-size: 1.5rem;
  color: #333;
  margin-bottom: 20px;
}

.dropzone {
  border: 2px dashed #3498db;
  padding: 20px;
  cursor: pointer;
  border-radius: 8px;
  transition: border-color 0.3s;
}

.dropzone:hover {
  border-color: #2980b9;
}

.fileName {
  margin-top: 10px;
  color: #333;
}

.uploadButton {
  margin-top: 20px;
  background-color: #3498db;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 8px;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s;
}

.uploadButton:hover {
  background-color: #2980b9;
}

.modalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
}

.modalContent {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  width: 400px;
  text-align: center;
}

.closeButton {
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
  position: absolute;
  top: 10px;
  right: 10px;
}

.modalTitle {
  font-size: 20px;
  margin-bottom: 20px;
}

.dropzone {
  border: 2px dashed #ccc;
  padding: 20px;
  margin-bottom: 20px;
}

.fileName {
  font-size: 14px;
  margin-top: 10px;
}

.uploadButton {
  background-color: #007bff;
  color: white;
  padding: 10px 20px;
  border: none;
  cursor: pointer;
  border-radius: 4px;
}

.modalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modalContent {
  background: #ffffff;
  border-radius: 10px;
  padding: 20px;
  width: 400px;
  max-width: 90%;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
  position: relative;
  text-align: center;
}

.closeButton {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 20px;
  cursor: pointer;
  color: #999;
}

.closeButton:hover {
  color: #333;
}

.modalTitle {
  font-size: 20px;
  margin-bottom: 20px;
  color: #333;
}

.dropzone {
  border: 2px dashed #76c7c0;
  border-radius: 10px;
  padding: 20px;
  background-color: #f9f9f9;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.dropzone:hover {
  background-color: #eefaf7;
}

.dropzoneWithFile {
  background-color: #eefaf7;
}

.fileName {
  font-size: 16px;
  margin-top: 10px;
  color: #555;
}

.errorMessage {
  color: #ff4d4d;
  margin-top: 10px;
  font-size: 14px;
}

.uploadButton {
  background-color: #76c7c0;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
  margin-top: 20px;
  transition: background-color 0.3s ease;
}

.uploadButton:disabled {
  background-color: #ddd;
  cursor: not-allowed;
}

.uploadButton:hover:enabled {
  background-color: #64b1aa;
}

.progressContainer {
  margin-top: 20px;
}

.progressBar {
  width: 100%;
  height: 10px;
  background-color: #e0e0e0;
  border-radius: 5px;
  overflow: hidden;
  margin-top: 10px;
}

.progress {
  height: 100%;
  background-color: #76c7c0;
  transition: width 0.2s ease;
}


.spinnerContainer{
  margin-top: 20px;
  display: flex;
  justify-content: center;
}

.spinner {
  border: 4px solid rgba(0, 0, 0, 0.1); /* Цвет фона */
  border-top: 4px solid #007bff; /* Цвет индикатора */
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}


@media screen and (max-width: 1096px) {
  .heroRight {
    width: 251px;
  }
}

@media screen and (max-width: 992px) {
  .heroRight {
    width: 200px;
    margin-top: 30px;
  }
}

@media screen and (max-width: 430px) {
  .hero {
    flex-direction: column;
    align-items: center;
    padding-top: 20px;
    margin-bottom: 120px;
    width: 100%;
  }
  .heroTitle {
    width: 100%;
  }
  .heroDescr {
    width: 100%;
  }
  .heroLeft {
    width: 100%;
    height: auto;
  }
  .heroRight {
  }
  .aboutContent {
    flex-direction: column;
  }
  .aboutImage {
    width: 80%;
    height: auto;
  }
  .aboutList {
    width: 100%;
  }
  .aboutText {
    width: 100%;
  }
  .aboutFooter {
    width: 100%;
  }
  .certificates {
  }
  .certificatesDescr {
    width: 100%;
    font-size: 16px;
  }
  .certificatesTitle {
    font-size: 38px;
  }
  .certificateImages {
    display: block;
    width: 100%;
  }
  .certificateImgLeft {
    width: 100%;
    margin-bottom: 50px;
  }
  .certificateImgRight {
    width: 100%;
    margin-bottom: 50px;
  }
  .testimonialCards {
    flex-direction: column;
  }
  .testimonialCard {
    width: 100%;
  }
  .testimonialsTitle {
    width: 100%;
    font-size: 48px;
  }
  .aboutList li a {
    font-size: 14px;
  }
  .aboutTitle {
    font-size: 38px;
  }
  .aboutUs {
    font-size: 18px;
  }
}

@media screen and (max-width: 375px) {
}

