.modal {
  position: fixed;
  overflow: auto;
  width: 100%;
  height: 100%;
  background: #00000080;
  color: #06283d;
  bottom:  0;
  left: 0;
  padding: 80px 0;
  margin: 0;
  margin-top: 0px;
  input {
    width: 100%;
    font-family: Manrope;
    border-radius: 5px;
    padding: 15px 20px;
    border: none;
    outline: none;
    font-size: 16px;
    color: #0099f8;
    border: 1px solid #0099f8;
    margin-bottom: 20px;
    &::placeholder {
      color: #0099f8;
      opacity: 0.4;
    }
  }
  textarea {
    max-width: 100%;
    border: 1px solid #0099f8;
    border-radius: 5px;
    font-size: 16px;
    padding: 15px 20px;
    color: #0099f8;
    font-weight: 400;
    outline: none;
    font-family: Manrope;
    margin-bottom: 20px;
    &::placeholder {
      color: #0099f8;
      opacity: 0.4;
    }
  }
  &__container {
    background: #fff;
    border-radius: 15px;
    padding: 30px;
    background-color: #fcfcff;
  }
  &__title {
    margin-bottom: 30px;
    border-bottom: 1px solid rgb(215, 215, 215);
    padding-bottom: 10px;
    font-size: 22px;
  }
  &__block {
    display: flex;
    justify-content: space-around;
    margin-bottom: 30px;
  }
  &__form {
    width: 35%;
    display: flex;
    flex-direction: column;
  }

  &__preview {
    width: 50%;
    h2 {
      margin-bottom: 20px;
    }
    h3 {
      font-size: 22px;
      margin-bottom: 10px;
    }
    img {
      width: 100%;
      margin-bottom: 20px;
    }
    span {
      color: orange;
      font-size: 18px;
      font-weight: 600;
    }
    p {
      margin-bottom: 20px;
    }
  }
  &__btn {
    button {
      display: flex;
      align-items: center;
      background: #0099f8;
      font-size: 16px;
      padding: 16px 20px;
      border-radius: 10px;
      color: #fff;
      font-weight: 500;
      outline: none;
      border: none;
      cursor: pointer;
      span {
        margin-left: 10px;
      }
    }
  }
  &__video-form {
    width: 50%;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.04);
    margin-bottom: 20px;
    padding: 20px 30px;
    margin: 25px auto;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    input {
      margin: 10px auto;
    }
    textarea {
      margin-top: 10px;
    }
    span {
      display: flex;
      justify-content: flex-end;
      color: #0099f8;
      cursor: pointer;
    }
  }
  &__module {
    width: 50% !important;
  }
  &__btns {
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;
    gap: 20px;
    button {
    }
  }
}

.succes_btn {
  background-color: rgb(0, 210, 0) !important;
  padding-left: 40px !important;
  padding-right: 40px !important;
}

.cancel_btn {
  background-color: tomato !important;
  padding-left: 30px !important;
  padding-right: 30px !important;
}
