.elippsis {
  width: 70%; /* Ширина контейнера */
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1; /* Количество видимых строк */
  overflow: hidden;
}
.course-overview {
  width: 100%;
  background: rgb(224, 15, 97);
  background: linear-gradient(
    153deg,
    rgba(224, 15, 97, 1) 54%,
    rgba(0, 204, 212, 1) 100%
  );
  color: white;
  padding: 2rem;
  border-radius: 10px;
  display: flex;
  justify-content: space-around;
  margin-top: 100px;
}

.course-info {
  display: flex;
  justify-content: end;
  font-weight: 800;
}
.course-price {
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.course-buttons a {
  background-color: #fff;
  color: #006a6e;
  font-weight: 800;
  border-radius: 5px;
  padding: 8px 10px;
  cursor: pointer;
}

.modules-list {
  padding: 2rem;
}

.modules-list h2 {
  color: #ff69b4;
}

.module {
  margin-bottom: 2rem;
}

.module h3 {
  background-color: #00ced1;
  color: white;
  padding: 0.5rem;
}

.module ul {
  list-style: none;
  padding: 0;
}

.module li {
  background-color: #008080;
  color: white;
  padding: 0.5rem;
  margin: 0.5rem 0;
}

.course-details {
  padding: 2rem;
}

.course-details h2 {
  color: #ff69b4;
  margin-bottom: 1rem;
}

.course-details ol {
  list-style-position: inside;
}

.course-benefits {
  display: flex;
  justify-content: space-between;
  margin-top: 2rem;
}

.course-author {
  margin-top: 2rem;
  text-align: center;
}

.course-right {
  // background: #000;
  width: 200px;
  height: 100px;
}
.course-right img {
  width: 100%;
}
.course-leftTitle {
  font-size: 40px;
  font-weight: 900;
  margin-bottom: 15px;
}

.course-leftDescr {
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 10px;
}
.course-priceInfo {
  display: flex;
  gap: 30px;
  font-weight: 800;
}
.course-priceDisabled {
  color: #99b5b6;
  text-decoration: line-through;
}

.course__choice {
  display: flex;
  justify-content: space-evenly;
  margin-top: 60px;
}
.course__choice button {
  padding: 10px 0px;
  color: #008080;
  background-color: #fff;
  border-radius: 25px;
  border: 2px solid #008080;
  font-size: 20px;
  font-weight: 700;
  max-width: 355px;
  width: 355px;
  cursor: pointer;
}

.course__choice .selected {
  background: #008080;
  color: #fff;
}

.course {
  &__details {
    margin-top: 100px;
    &-title {
      color: #006a6e;
      font-size: 30px;
      font-weight: 600;
      margin-bottom: 80px;
      b {
        font-size: 50px;
        font-weight: 900;
        margin-top: 8px;
      }
    }
    &-container {
      width: 800px;
      margin: 0 auto;
    }

    &-list {
      list-style-type: none;
      color: #006a6e;
      margin-bottom: 100px;
      li {
        background: #cdf5f7;
        padding: 15px 18px;
        font-size: 20px;
        font-weight: 600;
        border-radius: 10px;
        margin-bottom: 30px;
        display: flex;
        align-items: center;
        b {
          display: flex;
          justify-content: center;
          min-width: 70px;
          font-size: 70px;
          font-weight: 900;
          margin-right: 35px;
        }
      }
    }
  }
  &__benefits {
    display: flex;
    justify-content: space-between;
    &-container {
      width: 1000px;
      margin: 0 auto;
    }
    &-item {
      width: 300px;
    }
    &-img {
      width: 100%;
      text-align: center;
      margin-bottom: 35px;
      img {
        height: 100px;
        align-items: center;
      }
    }
    &-title {
      font-size: 20px;
      font-weight: 800;
      color: #006a6e;
      margin-bottom: 30px;
    }
    &-descr {
      font-weight: 600;
      color: #006a6e;
    }
  }
}

.modules {
  &__list {
    margin-top: 60px;
  }
  &__linetext {
    font-size: 30px;
  }
  &__video {
    width: 100%;
    height: 600px;
    margin-bottom: 80px;
  }
  &__item {
    color: #006a6e;
    background: #cdf5f7;
    border-radius: 10px;
    padding: 0 40px 50px 10px;
    margin-bottom: 46px;
    position: relative;
    &-header {
      display: flex;
      justify-content: space-between;
      align-items: end;
      margin-bottom: 45px;
    }
    &-btn {
      padding: 8px 36px;
      border-radius: 5px;
      background: #fff;
      font-weight: 800;
      cursor: pointer;
    }
    &-btnBuy {
      padding: 8px 36px;
      border-radius: 5px;
      background: #fff;
      font-weight: 800;
      cursor: pointer;
      position: absolute;
      z-index: 10;
      right: 5%;
    }
    &-title {
      font-size: 45px;
      font-weight: 900;
    }
  }
  &__lessons {
    list-style-type: none;
  }
  &__lesson {
    cursor: pointer;
    background: #006a6e;
    display: flex;
    color: #fff;
    font-size: 20px;
    font-weight: 800;
    padding: 8px 35px;
    border-radius: 5px;
    margin-bottom: 20px;
    margin-left: 25px;
    &-number {
      // margin-bottom: 10px;
      margin-right: 40px;
    }
    &-title {
      width: 80%;
    }
  }
  &__disabled {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    border-radius: 10px;
    background: #62848592;
    z-index: 9;
  }
}

.warning {
  margin-top: 50px;
  list-style-type: none;
  color: #006a6e;
  margin-bottom: 100px;
  background: #ffd8d8 !important;
  padding: 15px 18px;
  font-size: 20px;
  font-weight: 600;
  border-radius: 10px;
  margin-bottom: 30px;
  display: flex;
  align-items: center;
  b {
    display: flex;
    justify-content: center;
    min-width: 70px;
    font-size: 70px;
    font-weight: 900;
    margin-right: 35px;
  }
}

.videoPlayer {
  background: #000;
  width: "100%";
  color: #fff;
  height: 600px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}
.videoPlayerblock {
  background: transparent;
  top: 0;
  left: 0;
  width: 100%;
  height: 20%;
  position: absolute;
}

.videoPlayerblockBottom {
  background: transparent;
  bottom: 0;
  right: 0;
  width: 50%;
  height: 7%;
  position: absolute;
}
.rumbleBlock {
  background: transparent;
  position: absolute;
  bottom: 0;
  right: 0;
  height: 35px;
  width: 10%;
}
.rumbleBlockHeader{
  background: transparent;
  position: absolute;
  left: 0;
  top: 0;
  height: 35px;
  width: 100%;

}
.loading {
}

@media screen and (max-width: 768px) {
  .videoPlayerblockBottom {
    height: 8%;
  }
}

@media screen and (max-width: 1025px) {
  .videoPlayerblockBottom {
  }
}

@media screen and (max-width: 430px) {
  .videoPlayer {
    height: 200px;
  }
  .videoPlayerblock {
    height: 30%;
  }
  .videoPlayerblockBottom {
    height: 18%;
  }
  .course-right {
    display: none;
  }
  .course__choice {
    gap: 10px;
  }
  .course__choice button {
    font-size: 14px;
  }
  .course__details-container {
    width: 100%;
  }
  .course__details-title {
    font-size: 24px;
    b {
      font-size: 32px;
    }
  }
  .course__details-list li {
    font-size: 14px;
    b {
      font-size: 26px;
      min-width: 20px;
    }
  }
  .course__benefits-container {
    width: 100%;
  }
  .course-benefits {
    flex-direction: column;
    align-items: center;
    gap: 100px;
  }
  .modules__list li {
    font-size: 14px;
    b {
      font-size: 26px;
      min-width: 20px;
    }
  }
  .modules__video {
    height: 100%;
  }
  .modules__linetext {
    font-size: 22px;
  }
  .modules__item-title {
    font-size: 18px;
  }
  .modules__item-btn {
    display: none;
  }
  .modules__item {
    padding-top: 20px;
  }
  .course-price {
    gap: 10px;
  }
  .course-buttons button {
  }
  .modules__item-btnBuy {
    color: #006a6e;
  }
  .course-leftTitle {
    font-size: 32px;
  }
  .course-leftDescr {
    font-size: 18px;
  }
  .course-priceInfo {
    flex-direction: column;
    gap: 5px;
  }
}
