a {
  color: #fff;
}

.header {
  padding: 2rem 0 1rem;
}

.nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 50px;
}

.navList {
  width: 55%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  background: linear-gradient(45deg, #099ba1, #006a6e);
  border-top-right-radius: 30px;
  border-bottom-right-radius: 30px;
  list-style-type: none;
  margin: 0;
  padding-right: 5%;
  transition: max-height 0.3s ease-in-out;
}

.navList.open {
  max-height: 200px; /* Максимальная высота для мобильного меню */
}

.navItem {
  margin-left: 10%;
}

.navLogo {
  width: 80px;
}

.navLogo img {
  width: 100%;
}

.buttons {
  width: 30%;
  display: flex;
  align-items: center;
  justify-content: start;
  gap: 10%;
  height: 100%;
  background: linear-gradient(45deg, #006a6e, #099ba1);
  border-top-left-radius: 30px;
  border-bottom-left-radius: 30px;
  margin: 0;
  padding-left: 4%;
}

.button {
  color: #fff;
}

.buttonBack {
  color: #fff;
  background: red;
  cursor: pointer;
  border: 1px solid #fff;
  padding: 8px 20px;
  border-radius: 8px;
}

.buttonPrimary {
  border: 1px solid #fff;
  padding: 8px 20px;
  border-radius: 8px;
}

.hamburger {
  display: none;
  flex-direction: column;
  justify-content: space-around;
  width: 30px;
  height: 30px;
  background: transparent;
  border: none;
  cursor: pointer;
  z-index: 10;
}

.bar {
  width: 100%;
  height: 3px;
  background-color: #006a6f;
  transition: all 0.3s;
}

.hamburger.open .bar {
  background-color: #fff;
}

.hamburger.open .bar:nth-child(1) {
  transform: rotate(45deg) translateY(14px);
}

.hamburger.open .bar:nth-child(2) {
  opacity: 0;
}

.hamburger.open .bar:nth-child(3) {
  transform: rotate(-45deg) translateY(-14px);
}

@media screen and (max-width: 1024px) {
  .navItem,
  .buttons {
    font-size: 14px;
  }
}

@media screen and (max-width: 992px) {
  .navItem,
  .buttons {
    font-size: 12px;
  }
  .navList {
    padding-right: 4%;
  }
  .navItem {
    margin-left: 8%;
  }
}

@media screen and (max-width: 768px) {
  .navList {
    flex-direction: column;
    align-items: flex-start;
    position: absolute;
    right: 0;
    top: 0;
    width: 100%;
    max-height: 0;
    overflow: hidden;
    border-radius: 0 0 30px 30px;
  }
  .navItem {
    margin: 20px 0;
    width: 100%;
    text-align: center;
    font-size: 18px;
  }

  .hamburger {
    display: flex;
    margin-left: 10%;
  }

  .buttons {
    width: 40%;
  }
}

@media screen and (max-width: 425px) {
  .navLogo {
    font-size: 18px;
  }

  .buttons {
    width: 45%;
  }
}

@media screen and (max-width: 375px) {
  .navLogo {
    font-size: 16px;
  }

  .buttons {
  }

  .hamburger {
    display: flex;
    margin-left: 5%;
  }
}
