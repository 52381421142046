.login {
  &__container {
    margin-top: 80px;
  }
  &__block {
    max-width: 520px;
    margin: 0 auto;
    background: #22222f;
    box-shadow: 0px 4px 20px rgb(0 0 0 / 15%);
    border-radius: 20px;
    text-align: center;
    padding-top: 30px;
    padding-bottom: 40px;
  }
  &__title {
    font-family: Manrope;
    font-size: 34px;
    font-weight: 700;
    line-height: 46px;
    color: #fff;
  }
  &__form {
    max-width: 300px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    input {
      font-family: Manrope;
      text-align: center;
      border: none;
      outline: none;
      border-bottom: 1px solid #e7e7e7;
      font-size: 18px;
      color: #fff;
      margin-top: 30px;
      padding-bottom: 8px;
      background: #22222f;

      &::placeholder {
        font-weight: 400;
        color: #e7e7e7;
      }
    }
    span {
      color: red;
      font-size: 12px;
      text-align: left;
      margin-top: 5px;
    }
  }
  &__btn {
    margin: 0 auto;
    color: #fff;
    background-color: #22222f;

    border: 1px solid silver;
    font-family: Manrope;
    font-size: 18px;
    font-weight: 500;
    line-height: 22px;
    letter-spacing: 0em;
    text-align: center;
    padding: 12px 38px;
    border-radius: 5px;
    margin-top: 30px;
    cursor: pointer;
  }
  &__forgot {
    margin-top: 20px;
    color: #fff;
    font-size: 15px;
  }
}
