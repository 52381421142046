.footerContainer {
  background-color: #006a6e;
  border-radius: 8px;
  width: 100%;
}
.footerContent {
  display: flex;
  justify-content: space-between;
  padding: 55px 85px 65px 95px;
  color: white;
  margin: 220px 0 50px;
  font-size: 20px;
  font-weight: 600;
}

.footerText {
  width: 485px;
}

.footerContent h1 {
  color: white;
  margin-bottom: 48px;
  font-weight: 900;
  font-size: 30px;
}

.footerButtons {
  display: flex;
  flex-direction: column;
  gap: 44px;
  margin-bottom: 20px;
}

.footerBtn img {
  width: 30px;
  height: 30px;
  margin-right: 22px;
}

.footerBtn {
  display: flex;
  background-color: white;
  align-items: center;
  color: #006a6e;
  border: none;
  border-radius: 25px;
  padding: 10px 24px 10px 36px;
  font-size: 20px;
  font-weight: 800;
  cursor: pointer;
}

.footerBtn:hover {
  background-color: #e9ecef;
}

.footerBottom {
  display: flex;
  align-items: center;
}

.footerBottom img {
  width: 21px;
  height: 30px;
  margin-right: 28px;
}

.footerBottom p {
  color: white;
  font-size: 15px;
  font-weight: 600;
}

.footerBottomBtn {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

.footerDowmload {
  display: flex;
  align-items: center;
  background-color: #006a6e;
  color: white;
  border-radius: 5px;
  padding: 10px;
  font-size: 18px;
  font-weight: 700;
  cursor: pointer;
  margin-bottom: 125px;
  margin-right: 10px;
}

.footerDowmload p{
  margin-right: 10px;
}

.footerDowmload img {
  width: 28px;
  height: 35px;
  margin-left: 20px;
}

.footerDowmload:hover {
  background-color: #035e61;
}

@media screen and (max-width: 430px) {
  .footerText {
    display: none;
  }
  .footerContent {
    padding: 30px 25px;
  }
  .footerText{
    width: 100%;
  }
  .footerDowmload{
    font-size: 14px;
    font-weight: 600;
  }
}


/* @media screen and (max-width: 375px) {
  .footerText {
    display: none;
  }
  .footerContent {
    padding: 30px 25px;
  }
} */

