@import "./variables";

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  scroll-behavior: smooth;
}

body {
  font-family: Montserrat;
  margin: 0;
  padding: 0;
  // background: #24c6dc; /* fallback for old browsers */
  // background: -webkit-linear-gradient(
  //   to right,
  //   #514a9d,
  //   #24c6dc
  // ); /* Chrome 10-25, Safari 5.1-6 */
  // background: linear-gradient(
  //   to right,
  //   #514a9d,
  //   #24c6dc
  // ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  background: #ffffff;
}

button {
  font-family: Montserrat;
}

.container {
  // max-width: 1310px;
  max-width: 1096px;
  margin: 0 auto;
  padding: 0 15px;
}

@media (max-width: 1025px) {
  .container {
    max-width: 766px;
  }
}

@media (max-width: 431px) {
  .container {
    width: 400px;
  }
}

a {
  color: $mainColorLight;
  text-decoration: none;
}

h2 {
  margin: 0;
  padding: 0;
}

button {
  outline: none;
  border: none;
}

input {
  outline: none;
  border: none;
}

.lineText {
  color: #e00f61;
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 18px;
}

