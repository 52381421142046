.courses {
  margin-top: 100px;
  &__title {
    width: 500px;
    margin: 0 auto;
    text-align: center;
    color: #006a6e;
    font-size: 25px;
    font-weight: 900;
  }
}

@media screen and (max-width: 430px) {
  .courses__title {
    width: 100%;
  }
}
