.register {
  margin-top: 70px;
  font-family: Montserrat;
  color: #fff;
}

.main {
  background-image: url("../../assets/images/mainBannerBg.png");
  width: 100%;
  height: 300px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  justify-content: space-around;
}

.registerLeft {
  display: flex;
  flex-direction: column;
  width: 430px;
  height: 100%;
  text-align: center;
  justify-content: center;
}

.registerTitle {
  font-size: 30px;
  font-weight: 900;
  margin-bottom: 20px;
}

.registerBlock {
  margin-bottom: 10px;
}

.registerInput {
  width: 400px;
  height: 30px;
  background-color: #fff;
  border-radius: 5px;
  color: #006a6e;
  font-size: 14px;
  font-weight: 500;
  text-align: center;
}

.registerInput::placeholder {
  color: #99b5b6;
}

.registerError {
  display: none;
  color: rgb(255, 0, 0);
}

.registerSubmit {
  width: 250px;
  height: 30px;
  background-color: #fff;
  border-radius: 5px;
  color: #006a6e;
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 14px;
}

.registerSign {
  font-size: 14px;
  font-weight: 500;
}

.registerSign a {
  color: #211b59;
}

.registerRight {
  width: 262px;
  margin-top: 14px;
}

.registerRight img {
  width: 100%;
}

@media screen and (max-width: 430px) {
  .registerLeft {
    width: 100%;
  }
  .registerInput {
    width: 90%;
  }

  .registerRight {
    display: none;
  }
}
